import "./posts.style.css";

import { ControlButton } from "../components/ControlButton";
import { useSelector, useDispatch } from "react-redux";

import {
  setActivePost,
  selectActivePost,
  setPostText,
  setPostUsername,
} from "../posts/postsSlice";

import dayjs from "dayjs";
import { selectUser } from "../auth/userSlice";
import { FaBan, FaHistory, FaPencilAlt } from "react-icons/fa";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { sendRestoreNotification } from "./functions/restore/sendRestoreNotification";
import { sendRestoreMessage } from "./functions/restore/sendRestoreMessage";
import { writeRestoreBannedModeration } from "./functions/restore/writeRestoreBannedModeration";
import { writeRestoreHistory } from "./functions/restore/writeRestoreHistory";
import { restorePost } from "./functions/restore/restorePost";
import { logRestore } from "./functions/restore/amplitudeLogRestore";
import { ban_user_mod, db, logAnalytics } from "../../app/firebase";
import { logDelete } from "./functions/delete/amplitudeLogDelete";
import { changeDeleteReason } from "./functions/delete/changeDeleteReasonBannedModeration";
import changeDeleteMessage from "./functions/delete/changeDeleteMessage";
import { sendDeleteNotification } from "./functions/delete/changeDeleteNotification";
import { changeDeleteReasonHistory } from "./functions/delete/changeDeleteReasonHistory";
import {
  RiArrowGoBackLine,
  RiCheckDoubleFill,
  RiDeleteBin2Line,
} from "react-icons/ri";
import firebase from "firebase";
import store from "../../app/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import SearchUser from "../search/SearchUser";
import { Button } from "@mui/material";

export default function HistoryPost(props) {
  const { onChange } = props;
  let { uid } = useSelector(selectUser);

  const dispatch = useDispatch();

  const toastRef = useRef(null);

  const activePost = useSelector(selectActivePost);
  const s = useSelector((s) => s.posts);
  const u = useSelector((s) => s.user);
  let user = useSelector(selectUser);
  // console.log(s, "-------");
  // console.log(activePost,"-------");
  // console.log(props.history.action,"---------------")

  const [key, setKey] = useState("");
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleteCaptionVisible, setIsDeleteCaptionVisible] = useState(false);
  const [isDeleteUserName, setIsDeleteUserName] = useState(false);
  const [verificationUrl, setVerificationUrl] = useState(null);
  const [isPictureFullscreen, setIsPictureFullscreen] = useState(false);
  const [userName, setUserName] = useState("");
  const [postTextFor, setPostTextFor] = useState("");
  const [bio, setBio] = useState(null);
  const isActive = activePost == props.postId;
  const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });
  const [reviewModPopup, setReviewModPopup] = useState(false);

  const reviewMode = useMemo(() => props?.reviewMode, [props?.reviewMode]);
  const score = useMemo(() => props?.score, [props?.score]);
  const setScore = useMemo(() => props?.setScore, [props?.setScore]);
  const setModeratedCount = useMemo(
    () => props?.setModeratedCount,
    [props?.setModeratedCount]
  );
  const setAdminFilter = useMemo(
    () => props?.setAdminFilter,
    [props?.setAdminFilter]
  );
  const setReviewMode = useMemo(
    () => props?.setReviewMode,
    [props?.setReviewMode]
  );
  // const reviewedPosts = useMemo(
  //   () => props?.reviewedPosts,
  //   [props?.reviewedPosts, activePost]
  // );
  // const setReviewedPosts = useMemo(
  //   () => props?.setReviewedPosts,
  //   [props?.setReviewedPosts, activePost]
  // );
  const reviewedPost = props?.reviewedPost;
  const setReviewedPosts = props?.setReviewedPosts;
  const undoReviewedPost = props?.undoReviewedPost;
  const showResult = useMemo(() => props?.showResult, [props.showResult]);
  const setShowResult = useMemo(
    () => props?.setShowResult,
    [props?.setShowResult]
  );

  useEffect(() => {
    db.collection("users")
      .doc(props.userID)
      .get()
      .then((res) => {
        setBio(res.data()?.bio);

        setVerificationUrl(res.data()?.verifImgUrl);
      });
  }, [props.imageID, props.userID]);

  function handleDelete() {
    setIsDeleteVisible(true);
  }

  function handleDeleteUserName() {
    const data = {
      userID: props.userID,
      username: props.username,
      // location:"all_reported", "new_posts" , "all_posts" , "history"
      location: "history",
      docID: props.history.imageID,
    };

    // return
    const usernameModeration = firebase
      .functions()
      .httpsCallable("username_moderation");
    usernameModeration(data)
      .then((res) => {
        console.log(res);
        const { message, status, username } = res.data;
        if (status === 1) {
          // return
          // store.dispatch(
          //   setPostUsername({
          //     username: username,
          //     reported: false,
          //     unread: false,
          //   })
          // )
          setIsDeleteUserName(true);
          setUserName(username);
          deleteUsername(username);
          console.log(username);
        } else {
          setIsDeleteUserName(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    logHistoryDeleteUsername(data.username, data.docID);
  }

  async function logHistoryDeleteUsername(oldUsername = String, docID) {
    await db
      .collection("history")
      .doc(docID)
      .set({ edit_text: { username: oldUsername } }, { merge: true });
  }

  async function logHistoryDeleteCaption(oldCaption = String, docID) {
    await db
      .collection("history")
      .doc(docID)
      .set({ edit_text: { caption: oldCaption } }, { merge: true });
  }

  function deleteUsername(newUserName) {
    try {
      // db.collection("public_post")
      //   .doc(props.userID)
      //   .set({ username: newUserName }, { merge: true })
      //   .then((res) => {
      //     console.log(res);
      //   });
      db.collection("history")
        .doc(props.imageID)
        .set({ post_data: { username: newUserName } }, { merge: true })
        .then((res) => {
          // console.log(res);
        });
      // console.log(props);
      store.dispatch(
        setPostUsername({
          username: newUserName,
          reported: false,
          unread: false,
        })
      );
      console.log("finished");
      // dispatch(
      //   setPostUsername({
      //     username: newUserName,
      //     reported: false,
      //     unread: false,
      //   })
      // );
    } catch (err) {
      console.log(err);
      return err;
    }

    // db.collection("users")
    //   .doc(posts[activePost].userID)
    //   .set(
    //     {
    //       username: "inappropriate",
    //     },
    //     { merge: true }
    //   )
    //   .then(() => {
    //     console.log("changed username");
    //     setTextPrompt(false);
    // dispatch(
    //   setPostUsername({
    //     username: "inappropriate",
    //     reported: props.reported,
    //     unread: props.unread,
    //   })
    // );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  function handleDeleteCaption() {
    const data = {
      userID: props.userID,
      username: props.username,
      caption: props.postText,
      // location:"all_reported", "new_posts" , "all_posts" , "history"
      location: "history",
      docID: props.history.imageID,
    };
    console.log(data);
    // return
    const captionDeleteModeration = firebase
      .functions()
      .httpsCallable("caption_delete_moderation");
    captionDeleteModeration(data)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("first")
          // store.dispatch(
          //   setPostText({
          //     postText: "",
          //     reported: false,
          //     unread: false,
          //   })
          // );
          setIsDeleteCaptionVisible(true);
          captionDeleteFunction();
        } else {
          setIsDeleteCaptionVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // deleteUsername()
    logHistoryDeleteCaption(data.caption, data.docID);
  }

  function handleCopyUserId() {
    navigator.clipboard.writeText(props.userID).then(() => {
      console.log("ok", props.userID);
    });
  }

  function captionDeleteFunction() {
    try {
      // db.collection("public_post")
      //   .doc(props.userID)
      //   .set({ username: "" }, { merge: true })
      //   .then((res) => {
      //     console.log(res);
      //   });

      db.collection("history")
        .doc(props.imageID)
        .set({ post_data: { postText: "" } }, { merge: true })
        .then((res) => {
          console.log(res);
        });

      store.dispatch(
        setPostText({
          postText: "",
          reported: false,
          unread: false,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

  const unselect = useCallback(() => {
    dispatch(setActivePost(-1));
  }, [dispatch]);

  const restore = useCallback(() => {
    const collection = "public_post";

    restorePost({
      collection,
      userID: props.userID,
      post: props.post,
      moderatorID: user.uid,
    });

    sendRestoreNotification({ fcm_token: props.fcm_token });

    sendRestoreMessage({
      uid: props.userID,
      username: props.username,
      emoji: props.emoji ?? "⚠️",
    });

    writeRestoreBannedModeration({
      docID: props.userID,
      last_reason: props.history.reason,
    });

    writeRestoreHistory({ imageID: props.imageID, modID: user.uid });

    logRestore({
      event: "history_edit",
      info: "restore",
      post: props.post,
    });
    alert("Restored post!");

    onChange();

    unselect();
  }, [onChange, props, unselect, user.uid]);

  function unban() {
    ban_user_mod({
      uid: props?.userID,
      action: "un_ban_temp",
      location: "history",
    })
      .then((res) => {
        if (res.data.status !== 1) {
          return alert(`problem unbanning: ${res?.data?.message}`);
        }
        console.log("ban_user_mod response:", res.data.message);
        // alert(`unbanned post: ${res?.data?.message}`)
      })
      .catch((err) => {
        return alert(`problem unabnning for: ${err}`);
      });
  }

  function handleKeyDown(e) {
    setKey(e.key);
  }

  const changeReason = useCallback(
    ({ reason_report_int, reason_report }) => {
      changeDeleteReason({
        userID: props.userID,
        reason_report,
        reason_report_int,
      });

      changeDeleteMessage({
        username: props.username,
        uid: props.userID,
        emoji: props.emoji,
        reason: {
          reason_report,
          reason_report_int,
        },
      });

      sendDeleteNotification({ fcm_token: props.fcm_token });

      changeDeleteReasonHistory({
        imageID: props.imageID,
        userID: props.userID,
        reason_report,
        reason_report_int,
      });

      db.collection("public_post")
        .doc(props.userID)
        .delete()
        .then(() => {})
        .catch((err) => {
          console.err(err);
        });

      db.collection("moderation_post")
        .doc(props.userID)
        .delete()
        .catch((err) => {
          console.err(err);
        });

      logDelete({
        event: "history_edit",
        info: { action: "change reason" },
        post: props.post,
      });

      let banUserModReason = "";
      switch (reason_report) {
        case "Selling / Self-Promotion":
          banUserModReason = "selling";
          break;
        case "Nudity / Partial Nudity":
          banUserModReason = "nudity";
          break;
        case "Suggestive / Sexual Suggestion":
          banUserModReason = "sexual";
          break;
        case "Underage":
          banUserModReason = "underage";
          break;
        case "Against guidelines":
          banUserModReason = "guidelines";
          break;
        case "Illegal":
          banUserModReason = "illegal";
          break;
        case "Scam":
          banUserModReason = "scam";
          break;
        default:
          banUserModReason = "guidelines";
          break;
      }

      ban_user_mod({
        uid: props.userID,
        action: "ban_temp",
        location: "history",
        reason: banUserModReason,
      });

      onChange();
      unselect();
    },
    [
      onChange,
      props.emoji,
      props.fcm_token,
      props.imageID,
      props.post,
      props.userID,
      props.username,
      unselect,
    ]
  );
  const deleteForUnderage = useCallback(() => {
    alert("Deleted for underage");
    changeReason({ reason_report: "Underage", reason_report_int: 2 });
  }, [changeReason]);

  const deleteForNudity = useCallback(() => {
    changeReason({
      reason_report: "Nudity / Partial Nudity",
      reason_report_int: 1,
    });

    alert("Deleted for nudity");
  }, [changeReason]);

  const deleteForSelling = useCallback(() => {
    changeReason({
      reason_report: "Selling / Self-Promotion",
      reason_report_int: 3,
    });

    alert("Deleted for selling");
  }, [changeReason]);

  const deleteforSexual = useCallback(() => {
    changeReason({
      reason_report: "Suggestive / Sexual Suggestion",
      reason_report_int: 4,
    });

    alert("Deleted for sexual");
  }, [changeReason]);

  const deleteForScam = useCallback(() => {
    changeReason({
      reason_report_int: 5,
      reason_report: "Scam",
    });

    alert("Deleted for scam");
  }, [changeReason]);

  const deleteForIllegal = useCallback(() => {
    changeReason({
      reason_report_int: 6,
      reason_report: "Illegal",
    });

    alert("Deleted for illegal");
  }, [changeReason]);

  const deleteGeneral = useCallback(() => {
    changeReason({
      reason_report_int: 0,
      reason_report: "Against guidelines",
    });

    alert("Deleted for against guidelines");
  }, [changeReason]);

  function handleHideDeleteButtons() {
    setIsDeleteVisible(false);
  }
  // console.log(activePost,"activepost-------");
  const handleKeyUp = useCallback(
    ({ key }) => {
      if (showPopup.show) {
        if (key === "Escape") {
          if (showPopup.show)
            setShowPopup((currentState) => {
              return { show: false, ID: undefined };
            });
          return;
        }
      }
      if (!showPopup.show) {
        if (key === "ArrowRight") {
          // console.log(
          //   "activePost++++++++++++++++////////",
          //   activePost,
          //   props.limit
          // );
          if (!reviewMode && activePost + 1 === props.limit) {
            if (reviewMode) return;
            // console.log("runalwaiys");
            dispatch(setActivePost(0));
            props.nextPage();
          } else {
            logAnalytics({
              event_type: "next_history_webapp",
              data: {
                mod_uid: props?.history?.mod_uid,
                location: "post",
              },
            }).catch((err) => console.error("log err", err));
            dispatch(setActivePost(activePost + 1));
          }
        }

        if (key === "ArrowLeft") {
          if (activePost === 0) {
            if (reviewMode) return;
            if (props.page !== 0) {
              dispatch(setActivePost(props.limit - 1));
            } else {
              dispatch(setActivePost(-1));
            }
          } else {
            dispatch(setActivePost(activePost - 1));
          }
        }

        if (reviewMode) {
          if (key === "j") {
            setReviewedPosts(props.postId, true);
            setModeratedCount((prev) => prev + 1);
            if (activePost + 1 === props.limit) {
              setShowResult(true);
            } else dispatch(setActivePost(activePost + 1));
          }
          if (key === "f") {
            setReviewedPosts(props.postId, false);
            setModeratedCount((prev) => prev + 1);
            if (activePost + 1 === props.limit) {
              setShowResult(true);
            } else dispatch(setActivePost(activePost + 1));
          }
          if (key.toLowerCase() === "q") {
            handleCopyUserId();
            if (props?.userID)
              setShowPopup((currentState) => {
                return { show: true, ID: props?.userID };
              });
          }
          if (key === "Escape" || key === "a") {
              if (showResult) setShowResult(false);
              const confirmEnd = window.confirm(
                "Are you sure you want to end the session?"
              );
              if (confirmEnd) {
                setAdminFilter("");
                setReviewMode(false);
              }
          }
          return; // to disable other buttons in review mode
        }

        if (key === "h") {
          if (!reviewMode && activePost + 1 === props.limit) {
            console.log("runalwaiys");
            dispatch(setActivePost(0));
            props.nextPage();
          } else {
            dispatch(setActivePost(activePost + 1));
          }
        }

        if (key === "g") {
          if (activePost === -1) {
            console.log(activePost, "in-----g---if-con");
            if (props.page !== 0) {
              dispatch(setActivePost(props.limit - 1));
            } else {
              dispatch(setActivePost(-1));
            }
          } else {
            console.log(activePost, "in-----g---else-con");
            dispatch(setActivePost(activePost - 1));
          }
        }
        // console.log(activePost, "inner------ key down");
        if (key.toLowerCase() === "j" && props.history.action !== "keep") {
          restore();
          unban();
        }

        if (key.toLowerCase() === "u") {
          handleDeleteUserName();
        }

        if (key.toLowerCase() === "q") {
          handleCopyUserId();
          if (props?.userID)
            setShowPopup((currentState) => {
              return { show: true, ID: props?.userID };
            });
        }

        if (key.toLowerCase() === "c") {
          handleDeleteCaption();
        }

        if (key.toLowerCase() === "f") {
          handleDelete();
        }

        if (key.toLowerCase() === "1" && isDeleteVisible) {
          deleteForNudity();
        }

        if (key.toLowerCase() === "2" && isDeleteVisible) {
          deleteForUnderage();
        }
        if (key.toLowerCase() === "3" && isDeleteVisible) {
          deleteForSelling();
        }
        if (key.toLowerCase() === "4" && isDeleteVisible) {
          deleteforSexual();
        }
        if (key.toLowerCase() === "5" && isDeleteVisible) {
          deleteForScam();
        }
        if (key.toLowerCase() === "6" && isDeleteVisible) {
          deleteForIllegal();
        }
        if (key.toLowerCase() === "0" && isDeleteVisible) {
          deleteGeneral();
        }

        if (key === "Escape" || key === "a") {
          if (isDeleteVisible) {
            setIsDeleteVisible(false);
          } else if (isPictureFullscreen) {
            setIsPictureFullscreen(false);
          } else if (reviewMode) {
            if (showResult) setShowResult(false);
            const confirmEnd = window.confirm(
              "Are you sure you want to end the session?"
            );
            if (confirmEnd) {
              setAdminFilter("");
              setReviewMode(false);
            }
          } else {
            dispatch(setActivePost(-1));
          }
        }

        if (key === " ") {
          if (activePost === -1) {
            dispatch(setActivePost(0));
          } else {
            setIsPictureFullscreen(!isPictureFullscreen);
          }
        }

        if (key.toLowerCase() === "u") {
        }

        return key;
      }
    },
    [
      activePost,
      deleteForIllegal,
      deleteForNudity,
      deleteForScam,
      deleteForSelling,
      deleteForUnderage,
      deleteGeneral,
      deleteforSexual,
      dispatch,
      isDeleteVisible,
      isPictureFullscreen,
      props,
      restore,
      handleCopyUserId,
      handleDeleteCaption,
      handleDeleteUserName,
    ]
  );

  //   const handleKeyUp =(e)=>{
  //     console.log(activePost, "inner------ key down", isActive);
  //     if (e.key.toLowerCase() === "j" && props.history.action !== "keep") {
  //       restore();
  //     }

  //     if (e.key.toLowerCase() === "u") {
  //       handleDeleteUserName();
  //     }

  //     if (e.key.toLowerCase() === "q") {
  //       handleCopyUserId();
  //     }

  //     if (e.key.toLowerCase() === "c") {
  //       handleDeleteCaption();
  //     }

  //     if (e.key.toLowerCase() === "f") {
  //       handleDelete();
  //     }

  //     if (e.key.toLowerCase() === "1" && isDeleteVisible) {
  //       deleteForNudity();
  //     }

  //     if (e.key.toLowerCase() === "2" && isDeleteVisible) {
  //       deleteForUnderage();
  //     }
  //     if (e.key.toLowerCase() === "3" && isDeleteVisible) {
  //       deleteForSelling();
  //     }
  //     if (e.key.toLowerCase() === "4" && isDeleteVisible) {
  //       deleteforSexual();
  //     }
  //     if (e.key.toLowerCase() === "5" && isDeleteVisible) {
  //       deleteForScam();
  //     }
  //     if (e.key.toLowerCase() === "6" && isDeleteVisible) {
  //       deleteForIllegal();
  //     }
  //     if (e.key.toLowerCase() === "0" && isDeleteVisible) {
  //       deleteGeneral();
  //     }

  //     if (e.key === "Escape" || e.key === "a") {
  //       if (isDeleteVisible) {
  //         setIsDeleteVisible(false);
  //       } else if (isPictureFullscreen) {
  //         setIsPictureFullscreen(false);
  //       } else {
  //         dispatch(setActivePost(-1));
  //       }
  //     }

  //     if (e.key === " ") {
  //       if (activePost === -1) {
  //         dispatch(setActivePost(0));
  //       } else {
  //         setIsPictureFullscreen(!isPictureFullscreen);
  //       }
  //     }
  // if(activePost!==-1){
  //     if (e.key === "ArrowRight") {
  //       if (activePost + 1 === props.limit) {
  //         // console.log("call");
  //         dispatch(setActivePost(0));
  //         props.nextPage();
  //       } else {
  //         // console.log("not call");
  //         dispatch(setActivePost(activePost + 1));
  //       }
  //     }
  //     if (key === "h") {

  //       if (activePost + 1 === props.limit) {
  //         dispatch(setActivePost(0));
  //         props.nextPage();
  //       } else {
  //         dispatch(setActivePost(activePost + 1));
  //       }
  //     }

  //     if (e.key === "ArrowLeft") {
  //       if (activePost === 0) {
  //         if (props.page !== 0) {
  //           dispatch(setActivePost(props.limit - 1));
  //         } else {
  //           dispatch(setActivePost(-1));
  //         }
  //       } else {
  //         dispatch(setActivePost(activePost - 1));
  //       }
  //     }
  //     if (key === "g") {

  //       if (activePost === 0) {
  //         if (props.page !== 0) {
  //           dispatch(setActivePost(props.limit - 1));
  //         } else {
  //           dispatch(setActivePost(-1));
  //         }
  //       } else {
  //         dispatch(setActivePost(activePost - 1));
  //       }
  //     }}

  //     if (e.key.toLowerCase() === "u") {
  //     }
  //   };

  const isThumbnailDisplayed =
    true ||
    user.uid !== "H8rw3oWoEZZAcIAtVtUB5V82zZF2" ||
    props.userID === "D6qtfRnvACR31NRcOnKucWRWEVH2";

  useEffect(() => {
    if (!isActive) return;

    console.log("reviewedPost", reviewedPost);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return function () {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, isActive]);

  // useEffect(() => {
  //   // if (!isActive) return;

  //   document.addEventListener("keydown", handleKeyDown);
  //   document.addEventListener("keyup", handleKeyUp);

  //   return function () {
  //     document.removeEventListener("keydown", handleKeyDown);
  //     document.removeEventListener("keyup", handleKeyUp);
  //   };
  // }, []);

  // useEffect(() => {
  //   if(activePost !==-1){
  //     console.log("run effect in hiws")
  //     document.addEventListener("keyup", handleKeyUp);}
  //   // console.log("inhost-----------");
  //   return function () {
  //     document.removeEventListener("keyup", handleKeyUp);
  //   };
  // }, [activePost]);
  function handleSelect() {
    if (!isActive) dispatch(setActivePost(props.postId));
  }
  if (isActive) {
    // console.log(props, "============props============");
    return (
      <div
        className={`post ${isActive && "fullscreen"}`}
        style={reviewMode ? { backgroundColor: "white" } : {}}
      >
        {showPopup.show && showPopup.ID && (
          <div
            style={
              showPopup?.show && showPopup?.ID
                ? {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    width: "100vw",
                    height: "100vh",
                    zIndex: 900,
                    position: "fixed",
                    top: 0,
                    left: 0,
                  }
                : {}
            }
          >
            <div
              className="chatpopup_container"
              style={{
                position: "fixed", // Or 'absolute', if you prefer
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Centers the div
                width: "80vw",
                border: "2px solid grey",
                borderRadius: "10px",
                backgroundColor: "white",
                zIndex: 900,
                // Ensure content is scrollable if it overflows
                height: "90vh",
                overflowY: "scroll",
              }}
            >
              <button
                className="close"
                style={{ position: "sticky" }}
                onClick={() =>
                  setShowPopup((currentState) => {
                    return { show: false, ID: undefined };
                  })
                }
              >
                Close
              </button>
              <SearchUser
                deletePosts={false}
                isDevelopment={false}
                userID={showPopup.ID ? showPopup.ID : undefined}
              />
            </div>
          </div>
        )}
        {!isPictureFullscreen && (
          <div
            onClick={() => {
              if (reviewMode) {
                const confirmEnd = window.confirm(
                  "Are you sure you want to end the session?"
                );
                if (confirmEnd) {
                  setReviewMode(false);
                }
              }
              dispatch(setActivePost(-1));
            }}
            className="close"
          >
            {reviewMode ? "End Session" : "Close"}
          </div>
        )}
        <div className="left-panel">
          <div className="row">
            <div> Image ID: </div>
            <div> {props.history.imageID} </div>
          </div>
          <div className="row">
            <div> Action: </div>
            <div> {props.history.action} </div>
          </div>

          <div className="row">
            <div> Reason: </div>
            <div> {props.history.reason} </div>
          </div>

          <div className="row">
            <div> Location: </div>
            <div> {props.history.location} </div>
          </div>

          <div className="row">
            <div> Mods: </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {props.history.mods?.map((moderator, index) => {
                return <div key={index}>{moderator}</div>;
              })}
            </div>
          </div>

          <div className="row">
            <div> Date: </div>
            <div> {dayjs.unix(props.history.date.seconds).fromNow()} </div>
          </div>
        </div>
        {/* {console.log("hello", props.history.edit_text)} */}
        <div
          className="picture"
          {...(isPictureFullscreen ? { fullscreen: "true" } : {})}
        >
          {isPictureFullscreen && (
            <div
              className="close-fullscreen"
              onClick={() => setIsPictureFullscreen(false)}
            >
              Close
            </div>
          )}
          <img
            src={props?.history?.verification_url}
            alt={`${props.username}'s verification `}
            style={{ width: "30vh", height: "45vh" }}
          />
          <img
            src={props.imageUrlPost}
            alt={`${props.username}'s profile`}
            style={{ width: "40vh", height: "65vh" }}
          />
          <img
            src={props?.history?.profile_pic_url}
            alt={`${props.username}'s profile pic`}
            style={{ width: "35vh", height: "35vh" }}
          />
        </div>
        <div className="editText">
          {props.history.edit_text && props.history.edit_text.username && (
            <span>Username: {props.history.edit_text.username}</span>
          )}
          {props.history.edit_text &&
            props.history.edit_text.username &&
            props.history.edit_text.caption && <span>, </span>}
          {props.history.edit_text && props.history.edit_text.caption && (
            <span>Caption: {props.history.edit_text.caption}</span>
          )}
        </div>
        <div className="hidden postText_history_review">
          {props.history.action === "delete" && "🔴 "}
          {props.history.action === "keep" && "🟢 "}
          {props.history.action === "ban" && "🟠 "}

          {!userName ? props.username : `${userName}`}
          {` • `}
          {!isDeleteCaptionVisible ? `${props.postText}` : ""}

          {/* {(!isDeleteCaptionVisible || !isDeleteUserName) && `${props.username} • `} */}
          {/* {(!isDeleteCaptionVisible || !isDeleteUserName) && userName} */}
          {/* {props.postText && `${props.postText}`} */}
        </div>
        <div className="description">
          <small className="time">
            {dayjs.unix(props?.datePost?.seconds || props?.datePost).fromNow()}
          </small>

          <b> {props.username} </b>

          <p> ID: {props.userID} </p>

          <p> age: {props.age} </p>
          <p> country: {props.country} </p>
          <p> gender: {props.gender} </p>
          <p>
            {" "}
            bio:{" "}
            {props.history.edit_text?.bio
              ? "🔴 " + props.history.edit_text?.bio
              : props.bio}{" "}
          </p>

          <div style={{ textAlign: "left" }}>
            <b> {props.post_seen_by?.length ? "Seen by:" : "Unseen"} </b>
            {props.post_seen_by?.map((moderator) => {
              return <div key={moderator}>{moderator}</div>;
            })}
          </div>

          {verificationUrl ? (
            <img
              className="verification-image"
              src={verificationUrl}
              alt="Verification"
            />
          ) : (
            <p> No verification img available </p>
          )}
        </div>

        {!reviewMode ? (
          (props?.userFilter ? (props?.postId === 0 ? true : false) : true) && (
            <div className={`buttons ${isDeleteVisible && "many"}`}>
              {isDeleteVisible ? (
                <>
                  <ControlButton
                    letter="1"
                    activeKey={key}
                    description="Nudity / Partial"
                    icon={<FaBan />}
                    onClick={deleteForNudity}
                  />
                  <ControlButton
                    letter="2"
                    activeKey={key}
                    description="Underage"
                    icon={<FaBan />}
                    onClick={deleteForUnderage}
                  />
                  <ControlButton
                    letter="3"
                    activeKey={key}
                    description="Selling / Self-Promotion"
                    icon={<FaBan />}
                    onClick={deleteForSelling}
                  />
                  <ControlButton
                    letter="4"
                    activeKey={key}
                    description="Suggestive / Sexual Suggestion"
                    icon={<FaBan />}
                    onClick={deleteforSexual}
                  />
                  <ControlButton
                    letter="5"
                    activeKey={key}
                    description="Scam"
                    icon={<FaBan />}
                    onClick={deleteForScam}
                  />

                  <ControlButton
                    letter="6"
                    activeKey={key}
                    description="Illegal"
                    icon={<FaBan />}
                    onClick={deleteForIllegal}
                  />

                  <ControlButton
                    letter="0"
                    activeKey={key}
                    description="Against Guidelines"
                    icon={<FaBan />}
                    onClick={deleteGeneral}
                  />

                  <ControlButton
                    letter="a"
                    activeKey={key}
                    description="Back"
                    icon={<RiArrowGoBackLine />}
                    onClick={handleHideDeleteButtons}
                  />
                </>
              ) : (
                <>
                  <ControlButton
                    onClick={handleDelete}
                    letter="f"
                    activeKey={key}
                    description="Delete"
                    icon={<FaBan />}
                  />

                  {/* Delete Username */}
                  <ControlButton
                    onClick={handleDeleteUserName}
                    // letter="c"
                    letter="u"
                    activeKey={key}
                    description="Username"
                    icon={<FaBan />}
                  />

                  {/* Delete Caption */}
                  {props.history.action === "keep" && (
                    <ControlButton
                      onClick={handleDeleteCaption}
                      // letter="u"
                      letter="c"
                      activeKey={key}
                      description="Caption"
                      icon={<FaBan />}
                    />
                  )}

                  {props.history.action !== "keep" && (
                    <ControlButton
                      onClick={() => {
                        restore();
                        unban();
                      }}
                      letter="j"
                      description="Restore"
                      icon={<FaHistory />}
                      activeKey={key}
                    />
                  )}
                </>
              )}
              {/* <ControlButton
            onClick={handleCopyUserId}
            // letter="c"
            letter="q"
            activeKey={key}
            description="Copy"
            icon={<FaBan />}
          /> */}
            </div>
          )
        ) : !reviewedPost ? (
          <div className={`buttons ${isDeleteVisible && "many"}`}>
            <ControlButton
              letter="J"
              description="Accept"
              icon={<RiCheckDoubleFill />}
              onClick={() => {
                setReviewedPosts(props.postId, true);
                setModeratedCount((prev) => prev + 1);
                if (activePost + 1 === props.limit) {
                  setShowResult(true);
                } else dispatch(setActivePost(activePost + 1));
              }}
            />
            <ControlButton
              letter="F"
              description="Reject"
              icon={<RiDeleteBin2Line />}
              onClick={() => {
                setReviewedPosts(props.postId, false);
                setModeratedCount((prev) => prev + 1);
                if (activePost + 1 === props.limit) {
                  setShowResult(true);
                } else dispatch(setActivePost(activePost + 1));
              }}
            />
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                undoReviewedPost(props.postId);
                setModeratedCount((prev) => prev - 1);
              }}
              style={{
                position: "fixed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "22px",
                bottom: "45px",
                left: "45%",
                width: "100px",
                height: "50px",
                zIndex: 9999999,
                color: "black",
                backgroundColor: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  backgroundColor: "grey",
                  color: "white",
                },
              }}
            >
              Undo
            </button>
          </div>
        )}
        <Toast ref={toastRef} />
      </div>
    );
  }

  return (
    <div>
      <div className={"post"} onClick={handleSelect}>
        {isThumbnailDisplayed && (
          <img
            {...(user.developer ? { developer: "true" } : {})}
            src={props.imageUrlPost}
            alt={`${props.username}'s profile`}
          />
        )}

        <div className="description">
          {props.changed ? (
            "Updated"
          ) : (
            <>
              {props.history.edit_text ? (
                <span>
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    size="sm"
                    style={{
                      "--fa-primary-color": "#d4c411",
                      "--fa-secondary-color": "#d40c0c",
                    }}
                  />
                  {props.history.action === "ban" && "🟠"}
                  {props.history.action === "keep" && "🟢"}
                  {props.history.action === "delete" && "🔴"} {props.username} •{" "}
                  {props.postText}
                </span>
              ) : (
                <span>
                  {props.history.action === "ban" && "🟠"}
                  {props.history.action === "keep" && "🟢"}
                  {props.history.action === "delete" && "🔴"} {props.username} •{" "}
                  {props.postText}
                </span>
              )}
              {/* {props.history.action === "ban" && "🟠"}
              {props.history.action === "keep" && "🟢"}
              {props.history.action === "delete" && "🔴"} {props.username} •{" "}
              {props.postText} */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
